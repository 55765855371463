<template>
  <div class="container-fluid m-5">
    <h1>Oops!</h1>
    <p>404 no se encontró</p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style></style>
